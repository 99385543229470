import React, { Component } from 'react';

import './Metadata.css';

import Tags from './Tags';

const LEVEL = {
    MINIMUM: 'minimum',
    REGULAR: 'regular',
    COMPLETE: 'complete',
};

class Metadata extends Component {
    render() {
        const level = this.props.level || LEVEL.REGULAR;
        const { location, people, date, tags } = this.props.photograph;

        let locationAddress = [
            location.name,
            location.town,
        ];

        if (level !== LEVEL.MINIMUM) {
            locationAddress.push(location.province);
        }

        locationAddress.push(location.country);
        locationAddress = locationAddress.filter(string => !!string);
        const locationName = locationAddress.shift();

        const peopleComponent = level !== LEVEL.MINIMUM && people && people.length
            ? <div className="People">{ people.join(', ') }</div>
            : null;

        const localizedDate = new Date(date).toLocaleDateString(undefined, { 
            month: level === LEVEL.MINIMUM ? 'short' : 'long', 
            day: level === LEVEL.MINIMUM ? undefined : 'numeric',
        });

        const localizedYear = new Date(date).toLocaleDateString(undefined, { 
            year: 'numeric', 
        });

        let optionalExpandedMetadata;

        if (level === LEVEL.COMPLETE) {
            optionalExpandedMetadata = (
                <div className="Row">
                  <Tags tags={tags} />
                </div>
            )
        } 

        let optionalBreakTag;

        if (level === LEVEL.MINIMUM) {
            optionalBreakTag = (<br />)
        }

        return (
            <div className={`Metadata ${level}`}>
                  <div className="Row">
                      <div className="Location"><b>{ locationName }</b>{ optionalBreakTag } { locationAddress.join(', ') }</div>
                      { peopleComponent }
                      <div className="Date">{ localizedDate } <b>{ localizedYear }</b></div>
                  </div>
                  { optionalExpandedMetadata }
            </div>
        );
    }
}

Metadata.LEVEL = LEVEL;

export default Metadata;
