import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

const PATH = '/p';

class PhotographDetailLink extends Component {
  render() {
    const { 
        identifier, 
        children,
        includeFromSearchParam,
        location,
    } = this.props;

    const linkChildren = children || identifier;
    let searchParam = '';

    if (includeFromSearchParam) {
      searchParam = `?from=${encodeURIComponent(location.pathname + location.search)}`;
    }

    return (<Link key={identifier} to={`${PATH}/${identifier}${searchParam}`}>{ linkChildren }</Link>);
  }
}

export default withRouter(PhotographDetailLink);
