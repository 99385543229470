import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import LogoCircle from './Logo_Circle.png';
import LogoCircle2x from './Logo_Circle@2x.png';
import LogoCircle3x from './Logo_Circle@3x.png';
import './Logo.css';

class Logo extends Component {
  render() {
    return (
      <div className="Logo">
      		<Link to={`/`}>
          		<img className="Logo-circle" src={LogoCircle} srcSet={`${LogoCircle} 1x, ${LogoCircle2x} 2x, ${LogoCircle3x} 3x`} alt="Circle with angle gradient" />
          		<h1 className="Logo-name">cjfb</h1>
          	</Link>
      </div>
    );
  }
}

export default Logo;
