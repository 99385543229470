import React from 'react';
import { Link } from 'react-router-dom';

import SearchLink from "../Search/SearchLink";
import FilteredPhotographList from "../Photograph/List/FilteredPhotographList";
import PhotographDetail from "../Photograph/Detail/PhotographDetail";
import LatestPhotographList from "../Photograph/List/LatestPhotographList";

const Routes = [
    {
        path: '/',
        exact: true,
        render: ({ match, location }) => (<LatestPhotographList />),
        backLink: (url) => (<Link className='BackLink' to={'/'}><b>←</b> Home</Link>),
    },
    {
        path: '/p/:identifier',
        render: ({ location, match }) => {
            const searchParams = new URLSearchParams(location.search);    
            const identifier = match.params.identifier;
            const fromURL = searchParams.get('from');
            const shouldShowMetadata = searchParams.get('showMetadata') === '1';

            return (<PhotographDetail identifier={identifier} fromURL={fromURL} shouldShowMetadata={shouldShowMetadata} />);
        },
    },
    {
        path: '/search',
        render: ({ match, location }) => {
            const searchParams = new URLSearchParams(location.search);
            const searchTerm = searchParams.get('q');
            const chromaString = searchParams.get('c');
            const lightnessString = searchParams.get('l');

            return (<FilteredPhotographList searchTerm={searchTerm} chromaString={chromaString} lightnessString={lightnessString} />);
        },
        backLink: (url) => {
            // FIXME: This works, but perhaps react router provides a way to do this.
            const search = url.split('?')[1];
            const searchParams = new URLSearchParams(search);
            const query = searchParams.get('q');

            if (query) {
                return (
                    <SearchLink className='BackLink' query={query}><b>←</b> Search Results for <b><i>{query}</i></b></SearchLink>
                );
            }

            return (
                <SearchLink className='BackLink'><b>←</b> Search Results</SearchLink>
            );
        },
    },
];

export default Routes;