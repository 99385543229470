import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Routes from './Routes/Routes';

import './App.css';

class App extends Component {
    render() {    
        return (
            <Router>
                <div className="App">
                    <Helmet title='cjfb' />
                    <div className="Container">
                        <Switch>
                        { 
                            Routes.map((route, i) => (
                                <Route key={i} path={route.path} exact={route.exact} render={route.render} />
                            ))
                        }
                        </Switch>
                    </div>
                </div>
            </Router>
        );
    }
}

export default App;
