import React, { Component } from 'react';

import './PhotographImage.css';

const SELECTION_MODE = {
    ADAPTIVE_NATURAL_ASPECT_RATIO: 'adaptive-natural-aspect-ratio',
    LARGE_NATURAL_ASPECT_RATIO: 'large-natural-aspect-ratio',
    SMALL_16x9_COVER: 'small-16x9-cover',
};

// See Scripts/photographs-configuration.js
const IMAGE_SIZE = {
    FIT_2560w: 'fit2560w',
    FIT_1280w: 'fit1280w',
    FIT_640w: 'fit640w',
    COVER_768w432h: 'cover768w432h',
    COVER_384w216h: 'cover384w216h',
};

const attributesForImagesAndSelectionMode = (images, selectionMode) => {
    switch (selectionMode) {
        case SELECTION_MODE.ADAPTIVE_NATURAL_ASPECT_RATIO:
            return {
                src: images[IMAGE_SIZE.FIT_1280w],
                srcSet: `${images[IMAGE_SIZE.FIT_640w]} 640w, ${images[IMAGE_SIZE.FIT_1280w]} 1280w, ${images[IMAGE_SIZE.FIT_2560w]} 2560w`,
                // These image will occupy (roughly) the full width of the screen. 
                // FIXME: Not a good assumption to hard-code here.
                sizes: '100vw',
            }
        case SELECTION_MODE.LARGE_NATURAL_ASPECT_RATIO:
            return {
                src: images[IMAGE_SIZE.FIT_2560w],
            };
        case SELECTION_MODE.SMALL_16x9_COVER:
            return {
                src: images[IMAGE_SIZE.COVER_384w216h],
                srcSet: `${images[IMAGE_SIZE.COVER_384w216h]} 384w, ${images[IMAGE_SIZE.COVER_768w432h]} 768w`,
                // The max size (in points) the images are shown.
                // FIXME: Not a good assumption to hard-code here.
                sizes: '384px',
            };
        default:
            console.error('Invalid image selection mode for images', images);
            return {};
    }
};

class PhotographImage extends Component {
    render() {
        const {
            photograph,
            selectionMode = SELECTION_MODE.ADAPTIVE_NATURAL_ASPECT_RATIO,
            onClick
        } = this.props;

        const {
            images,
            location,
            tags,
        } = photograph;
        const attributes = attributesForImagesAndSelectionMode(images, selectionMode);

        return (<img className="PhotographImage"
            onClick={onClick}
            src={attributes.src} 
            srcSet={attributes.srcSet} 
            sizes={attributes.sizes}
            // FIXME: Location name is optional, so this be undefined.
            alt={`${location.name}: ${tags.join(', ')}`} 
            />);
    }
}

PhotographImage.SELECTION_MODE = SELECTION_MODE;

export default PhotographImage;

