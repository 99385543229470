import React, { Component } from 'react';

import PhotographList from './PhotographList';

import photographsData from '../../Data/photographs.json';
import photographsSortedByDateDescending from'../../Data/photographs-sorted-by-date-descending.json';

class LatestPhotographList extends Component {
    render() {
        const photographs = photographsSortedByDateDescending.map(identifier => {
            const photograph = photographsData[identifier];
            photograph.identifier = identifier;

            return photograph;
        });

        return (
            <PhotographList photographs={photographs} layout={PhotographList.LAYOUT.FULL_WIDTH} shouldHideMetadataOnSmallerScreens={true} />
        );
    }
}

export default LatestPhotographList;
