import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import './PhotographDetail.css';
import PhotographImage from '../PhotographImage';
import Metadata from '../Metadata/Metadata';
import Tags from '../Metadata/Tags';
import BackLinkForURL from '../../Routes/BackLinkForURL';

import photographsData from '../../Data/photographs.json';

class PhotographDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            shouldShowMetadata: true,
        };
    }

    onClick() {
        this.setState({
            shouldShowMetadata: !this.state.shouldShowMetadata,
        });
    }

    render() {
        const {
            identifier,
            fromURL,
        } = this.props;
        const { shouldShowMetadata } = this.state;

        const backLink = BackLinkForURL(fromURL);

        const photograph = photographsData[identifier];
        photograph.identifier = identifier;

        const { date, people, tags } = photograph;
        const { name, town, province, country } = photograph.location;

        const peopleString = people && people.length
            ? `${people.join(', ')} — `
            : '';

        const locationName = [
            name,
            town,
            province,
            country,
        ].find((value) => !!value);

        const localizedYear = new Date(date).toLocaleDateString(undefined, {
            year: 'numeric',
        });

        const showMetadataClassName = shouldShowMetadata ? 'showMetadata' : '';

        return (
            <div className='PhotographDetail'>
                <Helmet title={`${peopleString}${locationName}, ${localizedYear}`} />
                <div className={`PhotographContainer ${showMetadataClassName}`} >
                    <div className="Row">
                        { backLink }
                        <Tags tags={tags} />
                    </div>
                    <PhotographImage onClick={this.onClick.bind(this)}
                        photograph={photograph} 
                        selectionMode={PhotographImage.SELECTION_MODE.LARGE_NATURAL_ASPECT_RATIO} />
                    <Metadata photograph={photograph} level={Metadata.LEVEL.REGULAR} />
                </div>
            </div>
        );
    }
}

export default withRouter(PhotographDetail);
