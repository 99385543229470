import React, { Component } from 'react';

import './FilteredPhotographList.css';

import PhotographList from './PhotographList';

import photographsData from '../../Data/photographs.json';
import tagsData from '../../Data/tags.json';
import photographsSortedByDateDescending from'../../Data/photographs-sorted-by-date-descending.json';
import tagsSortedByAlphabeticallyAscending from'../../Data/tags-sorted-alphabetically-ascending.json';

class FilteredPhotographList extends Component {
    render() {
        const {
            searchTerm,
            chromaString,
            lightnessString,
        } = this.props;

        let matchedPhotoIds;

        if (!searchTerm) {
            matchedPhotoIds = new Set(photographsSortedByDateDescending);
        } else {
            const matchedTags = tagsSortedByAlphabeticallyAscending.filter(tag => {
                return tag.toLowerCase().includes(searchTerm.toLowerCase());
            });

            matchedPhotoIds = new Set();
            matchedTags.forEach(tag => {
                const photoIds = tagsData[tag];
                photoIds.forEach(photoId => matchedPhotoIds.add(photoId));
            });
        }

        const photographs = [];
        matchedPhotoIds.forEach(identifier => {
            const photograph = photographsData[identifier];
            const averageColour = photograph.averageColour;

            if (chromaString) {
                const chroma = averageColour[1];
                const chromaStringSplit = chromaString.split('...');
                const chromaMin = parseInt(chromaStringSplit[0], 10);
                const chromaMax = parseInt(chromaStringSplit[1], 10);

                if (chroma > chromaMax || chroma < chromaMin) {
                    return;
                }
            }

            if (lightnessString) {
                const lightness = averageColour[2];
                const lightnessStringSplit = lightnessString.split('...');
                const lightnessMin = parseInt(lightnessStringSplit[0], 10);
                const lightnessMax = parseInt(lightnessStringSplit[1], 10);

                if (lightness > lightnessMax || lightness < lightnessMin) {
                    return;
                }
            }

            photograph.identifier = identifier;
            photographs.push(photograph);
        });

        return (
            <PhotographList photographs={photographs} layout={PhotographList.LAYOUT.GRID} />
        );
    }
}

export default FilteredPhotographList;
