import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import './Photograph.css';
import PhotographImage from './PhotographImage';
import PhotographDetailLink from './Detail/PhotographDetailLink';
import Metadata from './Metadata/Metadata';

class Photograph extends Component {
    render() {
        const { 
            photograph,
            metadataLevel,
            imageSelectionMode,
            shouldWrapInLink = true,
        } = this.props;

        const { identifier } = photograph;
        const image = (<PhotographImage photograph={photograph} selectionMode={imageSelectionMode} />)

        return (
            <div className="Photograph">
                { shouldWrapInLink ? (
                    <PhotographDetailLink identifier={identifier} includeFromSearchParam={true}>
                        { image }
                    </PhotographDetailLink>
                ) : (
                    image
                )}
                <Metadata photograph={photograph} level={metadataLevel} />
            </div>
        );
    }
}

export default withRouter(Photograph);
