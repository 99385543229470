import React, { Component } from 'react';

import './PhotographList.css';

import Photograph from '../Photograph';
import PhotographImage from '../PhotographImage';
import Metadata from '../Metadata/Metadata';

import Logo from '../../Logo/Logo';

const LAYOUT = {
    GRID: 'grid',
    FULL_WIDTH: 'full-width',
};

const METADATA_LEVEL_FOR_LAYOUT = {
    [LAYOUT.FULL_WIDTH]: Metadata.LEVEL.REGULAR,
    [LAYOUT.GRID]: Metadata.LEVEL.MINIMUM,
};

const IMAGE_SELECTION_MODE_FOR_LAYOUT = {
    [LAYOUT.FULL_WIDTH]: PhotographImage.SELECTION_MODE.ADAPTIVE_NATURAL_ASPECT_RATIO,
    [LAYOUT.GRID]: PhotographImage.SELECTION_MODE.SMALL_16x9_COVER,
};

class PhotographList extends Component {
    render() {
        const {
            layout = LAYOUT.FULL_WIDTH,
            photographs,
            shouldHideMetadataOnSmallerScreens,
        } = this.props;

        const hideMetadataOnSmallerScreensClassName = shouldHideMetadataOnSmallerScreens 
            ? 'hideMetadataOnSmallerScreens'
            : '';
        const metadataLevel = METADATA_LEVEL_FOR_LAYOUT[layout];
        const imageSelectionMode =  IMAGE_SELECTION_MODE_FOR_LAYOUT[layout];

        const photographComponents = photographs.map(photograph => {
            return <Photograph 
                key={photograph.identifier}
                photograph={photograph} 
                metadataLevel={metadataLevel} 
                imageSelectionMode={imageSelectionMode} />
        });

        return [
            <Logo key="Logo" />,
            <div key="PhotographList" className={`PhotographList ${layout} ${hideMetadataOnSmallerScreensClassName}`}>
                <div>
                    { photographComponents }
                </div>
            </div>
        ];
    }
}

PhotographList.LAYOUT = LAYOUT;

export default PhotographList;
