import { matchPath } from 'react-router-dom';

import Routes from './Routes';

const BackLinkForURL = (url) => {
    if (!url) {
        return null;
    }
    
    for (let i = 0; i < Routes.length; i++) {
        const route = Routes[i];
        // FIXME: This works, but perhaps react router provides a way to do this.
        const path = url.split('?')[0];
        if (matchPath(path, route) && route.backLink) {
            return route.backLink(url);
        }
    }
};

export default BackLinkForURL;