import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const PATH = '/search';

class SearchLink extends Component {
    render() {
        const { 
            query, 
            children,
            className = '',
        } = this.props;

        const linkChildren = children || query;

        if (query) {
            return (
                <Link className={`SearchLink ${className}`} to={`${PATH}?q=${query}`}>{ linkChildren }</Link>
            );
        }

        return (
            <Link className={`SearchLink ${className}`} to={`${PATH}`}>{ linkChildren }</Link>
        );
    }
}

export default SearchLink;
