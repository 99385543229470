import React, { Component } from 'react';
import SearchLink from '../../Search/SearchLink';

import './Tags.css';

class Tags extends Component {
    render() {
        const { tags } = this.props;
        
        const tagComponents = tags.map((tag, index) => (<SearchLink key={tag} query={tag}/>));

        return (
            <div className="Tags">
                { tagComponents }
            </div>
        );
    }
}

export default Tags;
